import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'ng2-clippy',
  template: '',
  styleUrls: ['./node_modules/ng2-clippy/clippy.css']
})
export class ClippyAgent implements AfterViewInit {

  ngAfterViewInit() {
        System.import('./node_modules/ng2-clippy/clippy.min.js').then(function(clippy) {
            clippy.load('Merlin', function(agent){
                agent.show();
            });
        }).catch(function(err){ console.error(err); });
  }
}
